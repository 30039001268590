
<template>
  <div id="user-edit-tab-info">
    <div class="row">
       <div class="col-md-6">
          <div class="form-group">
            <label for="firstName">First name</label>
            <input id="firstName" type="text" class="form-control" v-model="data.first_name"
            v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
            <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="LastName">Last name</label>
            <input id="LastName" type="text" class="form-control" v-model="data.last_name"
            v-validate="'required|alpha_spaces'" placeholder="Last Name" name="last_name" />
            <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="inputEmail">Email Address</label>
            <input id="inputEmail" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" 
            v-validate="'required|email'" name="email" class="form-control"/>
            <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="inputMobile">Mobile</label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                 v-validate="{required: true, regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="phone" />
                <span class="text-danger font-sm"  v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
              <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="data.gender"
                  :options="genderOptions"
                  :aria-describedby="ariaDescribedby"
                  name="radio-gender"
                ></b-form-radio-group>
              </b-form-group>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
           <vue-dropzone id="dropzone" ref="profileImageDrop" 
           :use-custom-slot="true" :maxFiles="1">
              <div class="dz-message my-0 needsclick">
                  <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                  <h4>Drop files here or click to upload.</h4>
                  <span class="text-muted font-13">
                      (Dimension should be 450 x 450 Max: 100kb)
                  </span>
              </div>
          </vue-dropzone>
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-md-6">
          <div class="text-right">
            <button type="button" @click.prevent="updateUser" class="btn btn-primary mt-2">
                <i class="mdi mdi-content-save"></i> Update
            </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
  components: { VueDropzone },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        gender: "",
        is_verified: false
      },
    }
  },
  computed: {
    user(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        if(this.collectFiles().length){formData.append('image_file', this.collectFiles()[0]);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.resetForm()
              this.$store.commit('UPDATE_USER_INFO', response.data.data)
            }
        })
      })
    },
    patchUser(){
      this.data = this.$options.filters.patchUpdatables(this.data, this.user)
    },
    collectFiles(){
      var arrafile = []
      if(this.$refs.profileImageDrop){
        arrafile = this.$refs.profileImageDrop.getAcceptedFiles();
      }
      return arrafile
    },
    resetForm() {
      this.$refs.profileImageDrop.removeAllFiles();
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
