<template>
  <div class="row">
    <div class="col-12">
        <div class="form-group">
            <label for="old-password">Old Password</label>
            <input id="old-password" v-validate="'required|min:4|max:100'" type="password" class="form-control" placeholder="Enter password" name="old_password" v-model="data.old_password" />
            <span class="text-danger font-sm" v-show="errors.has('old_password')">{{ errors.first('old_password') }}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <label for="new-password">New Password</label>
            <input  v-model="data.password" id="new-password"  ref="password" type="password" class="form-control" v-validate="'required|min:4|max:100'" placeholder="Enter new password" name="password" />
            <span class="text-danger font-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <label for="confirm-password">Confirm Password</label>
            <input v-model="data.password_confirmation" id="confirm-password" type="password" class="form-control" v-validate="'required|min:4|max:100|confirmed:password'" data-vv-as="password" placeholder="Confirm Password" name="confirm_password"  />
            <span class="text-danger font-sm" v-show="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</span>
        </div>
    </div>

    <div class="col-12">
      <div class="text-right">
        <button type="button" @click.prevent="updateUser" class="btn btn-primary mt-2">
            <i class="mdi mdi-content-save"></i> Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: {
        old_password: "",
        password: "",
        password_confirmation: "",
      }
    }
  },
  methods:{
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (!result){ this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update-password',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.resetForm()
            }
        })
      });
    },
    resetForm() {
      this.data.old_password = this.data.password = this.data.password_confirmation = "";
      this.$validator.reset()
    },

  },
}
</script>
