
<template>
  <div id="user-edit-tab-info">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="d-block">Date of Birth</label>
          <date-picker v-model="data.dob" placehoder="Your DOB" :editable="false"
           :first-day-of-week="1" placeholder="choose date" format="YYYY-MM-DD" lang="en"></date-picker>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label>Country</label>
          <multiselect :options="countries.map(c => c.id)" :custom-label="parseCountry"
             v-model="data.country_id" placeholder="--select--"></multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-3">
          <label>Categories</label>
            <multiselect :options="categories"
              searchable
              label="name" track-by="id"
              :multiple="true"
              :close-on-select="false"
              v-model="data.categories" placeholder="--select categories--"></multiselect>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label>Tasks</label>
            <multiselect :options="tasks"
              searchable
              label="name" track-by="id"
              :multiple="true"
              :close-on-select="false"
              v-model="data.tasks" placeholder="--select tasks--"></multiselect>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="userbio">About</label>
          <textarea id="userbio"  v-model="data.about" class="form-control" rows="3" placeholder="Write something..." v-validate="'max:1000'" name="about"></textarea>
          <span class="text-danger font-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="social-fb">Facebook</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-facebook-square"></i>
              </span>
            </div>
            <input v-model="data.facebook" id="social-fb" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="facebook" />
          </div>
          <span class="text-danger font-sm"  v-show="errors.has('facebook')">{{ errors.first('facebook') }}</span>
        </div>
      </div>
      <div class="col-md-6">
          <div class="form-group">
              <label for="social-tw">Twitter</label>
              <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text">
                          <i class="fab fa-twitter"></i>
                      </span>
                  </div>
                  <input  v-model="data.twitter" id="social-tw" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="twitter"  />
              </div>
              <span class="text-danger font-sm"  v-show="errors.has('twitter')">{{ errors.first('twitter') }}</span>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="social-insta">Instagram</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-instagram"></i>
              </span>
            </div>
            <input v-model="data.instagram" id="social-insta" type="text" class="form-control" placeholder="Url" v-validate="'url:require_protocol'" name="instagram"/>
          </div>
          <span class="text-danger font-sm"  v-show="errors.has('instagram')">{{ errors.first('instagram') }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="social-gh">Github</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-github"></i>
              </span>
            </div>
            <input  v-model="data.github" id="social-gh" type="text" class="form-control" placeholder="url" v-validate="'url:require_protocol'" name="github" />
          </div>
          <span class="text-danger"  v-show="errors.has('github')">{{ errors.first('github') }}</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="text-right">
          <button type="button" @click.prevent="updateUser" class="btn btn-primary mt-2">
            <i class="mdi mdi-content-save"></i> Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      data:{
        about: "",
        dob: null,
        gender: "",
        country_id: null,
        twitter: "",
        facebook: "",
        instagram: "",
        github: "",
        tasks: [],
        categories: [],
      },
    }
  },
  computed: {
    user(){
      return this.$store.state.AppActiveUser
    },
    countries(){
      return this.$store.state.countries;
    },
    tasks(){
      return this.$store.state.catalog.tasks;
    },
    categories(){
      return this.$store.state.catalog.categories;
    },
  },
  methods: {
    parseCountry(id){
      let con = this.countries.find(item => item.id == id);
      if(con){ return con.name; }
    },
    updateUser(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update-info',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO',response.data.data)
            }
        })
      });
    },
    patchUser(){
      this.data = this.$options.filters.patchUpdatables(this.data, this.user)
      this.data.dob = this.data.dob ? new Date(this.data.dob) : null
    }
  },
  created(){
    this.patchUser()
    if(!this.tasks.length){
      this.$store.dispatch("fetchTasks")
    }
    if(!this.categories.length){
      this.$store.dispatch("fetchCategories")
    }
  },
}
</script>
